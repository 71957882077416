import React from "react"
import { Link } from "gatsby"
import velocidadImg1 from "../../images/velocidad1.svg"
import velocidadImg2 from "../../images/velocidad2.svg"
import velocidadImg3 from "../../images/velocidad3.svg"
import velocidadImg4 from "../../images/velocidad4.svg"

const Pricing = ({ noTitle }) => {
  return (
    <div className="container-fluid bg-light tarifas" id="tablatarifas">
      <div className="container">
        {!noTitle && (
          <>
            <h1 className="text-center mb-3" data-sal="slide-up">
              Pricing
            </h1>
            <h5 className="text-center mb-5 text-primary" data-sal="slide-up">
            Try OpenDatastage for free for 14 days. No credit card required.
            </h5>
          </>
        )}
        {/* <!--BLOCK ROW START--> */}
        <div className="row py-5 m-0">
          <div className="col-lg-3 col-md-12 mb-5 mb-lg-0" data-sal="slide-up">
            {/* <!--PRICE CONTENT START--> */}
            <div className="tarifas_content clearfix">
              {/* <!--HEAD PRICE DETAIL START--> */}
              <div className="tarifas_head_price clearfix">
                {/* <!--HEAD CONTENT START--> */}
                <div className="tarifas_head_content clearfix">
                  {/* <!--HEAD START--> */}

                  <div className="head">
                    <img src={velocidadImg1} />
                    <span>Bonus</span>
                  </div>
                  {/* <!--//HEAD END--> */}
                </div>
                {/* <!--//HEAD CONTENT END--> */}

                {/* <!--PRICE START--> */}
                <div className="tarifas_price_tag clearfix">
                  <span className="price">
                    <span className="currency">20</span>
                    <span className="sign">$  </span>
                  </span>
                </div>
                {/* <!--//PRICE END--> */}
              </div>
              {/* <!--//HEAD PRICE DETAIL END--> */}

              {/* <!--FEATURE LIST START--> */}
              <div className="tarifas_feature_list">
                <ul>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Up to 5</span> Max jobs
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Scheduled up to 93</span>
                    {/* <i
                      className="las la-question-circle text-secondary px-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Explicación de qué significa esto"
                    ></i> */}
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Valid until:</span> two months
                  </li>
                </ul>
              </div>
              {/* <!--//FEATURE LIST END--> */}

              {/* <!--BUTTON START--> */}
              <div className="tarifas_price_btn clearfix">
                <Link className="btn btn-primary" to="/signup">
                  START FREE TRIAL
                </Link>
              </div>
              {/* <!--//BUTTON END--> */}
            </div>
            {/* <!--//PRICE CONTENT END--> */}
          </div>

          <div className="col-lg-3 col-md-12 mb-5 mb-lg-0" data-sal="slide-up">
            {/* <!--PRICE CONTENT START--> */}
            <div className="tarifas_content clearfix">
              {/* <!--HEAD PRICE DETAIL START--> */}
              <div className="tarifas_head_price clearfix">
                {/* <!--HEAD CONTENT START--> */}
                <div className="tarifas_head_content clearfix">
                  {/* <!--HEAD START--> */}

                  <div className="head">
                    <img src={velocidadImg2} />
                    <span>Puppy</span>
                  </div>
                  {/* <!--//HEAD END--> */}
                </div>
                {/* <!--//HEAD CONTENT END--> */}

                {/* <!--PRICE START--> */}
                <div className="tarifas_price_tag clearfix">
                  <span className="price">
                    <span className="currency">150</span>
                    <span className="sign">$  </span>
                  </span>
                </div>
                {/* <!--//PRICE END--> */}
              </div>
              {/* <!--//HEAD PRICE DETAIL END--> */}

              {/* <!--FEATURE LIST START--> */}
              <div className="tarifas_feature_list">
                <ul>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Up to 140</span> Max jobs
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Scheduled up to 4.340</span>
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    Per month
                  </li>
                </ul>
              </div>
              {/* <!--//FEATURE LIST END--> */}

              {/* <!--BUTTON START--> */}
              <div className="tarifas_price_btn clearfix">
                <Link className="btn btn-primary" to="/signup">
                  START FREE TRIAL
                </Link>
              </div>
              {/* <!--//BUTTON END--> */}
            </div>
            {/* <!--//PRICE CONTENT END--> */}
          </div>

          <div
            className="col-lg-3 col-md-12 activada mb-5 mb-lg-0"
            data-sal="slide-up"
          >
            {/* <!--PRICE CONTENT START--> */}
            <div className="tarifas_content active clearfix">
              {/* <!--HEAD PRICE DETAIL START--> */}
              <div className="tarifas_head_price clearfix">
                {/* <!--HEAD CONTENT START--> */}
                <div className="tarifas_head_content clearfix">
                  {/* <!--HEAD START--> */}

                  <div className="head">
                    <img src={velocidadImg3} />
                    <span>Junior</span>
                  </div>
                  {/* <!--//HEAD END--> */}
                </div>
                {/* <!--//HEAD CONTENT END--> */}

                {/* <!--PRICE START--> */}
                <div className="tarifas_price_tag clearfix">
                  <span className="price">
                    <span className="currency">300</span>
                    <span className="sign">$  </span>
                  </span>
                </div>
                {/* <!--//PRICE END--> */}
              </div>
              {/* <!--//HEAD PRICE DETAIL END--> */}

              {/* <!--FEATURE LIST START--> */}
              <div className="tarifas_feature_list">
                <ul>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Up to 320</span> Max jobs
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Scheduled up to 9.920</span>
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    Per month
                  </li>
                </ul>
              </div>
              {/* <!--//FEATURE LIST END--> */}

              {/* <!--BUTTON START--> */}
              <div className="tarifas_price_btn clearfix">
                <Link className="btn btn-primary" to="/signup">
                  START FREE TRIAL
                </Link>
              </div>
              {/* <!--//BUTTON END--> */}
            </div>
            {/* <!--//PRICE CONTENT END--> */}
          </div>
          <div className="col-lg-3 col-md-12" data-sal="slide-up">
            {/* <!--PRICE CONTENT START--> */}
            <div className="tarifas_content clearfix">
              {/* <!--HEAD PRICE DETAIL START--> */}
              <div className="tarifas_head_price clearfix">
                {/* <!--HEAD CONTENT START--> */}
                <div className="tarifas_head_content clearfix">
                  {/* <!--HEAD START--> */}

                  <div className="head">
                    <img src={velocidadImg4} />
                    <span>Senior</span>
                  </div>
                  {/* <!--//HEAD END--> */}
                </div>
                {/* <!--//HEAD CONTENT END--> */}

                {/* <!--PRICE START--> */}
                <div className="tarifas_price_tag clearfix">
                  <span className="price">
                    <span className="currency">600</span>
                    <span className="sign">$  </span>
                  </span>
                </div>
                {/* <!--//PRICE END--> */}
              </div>
              {/* <!--//HEAD PRICE DETAIL END--> */}

              {/* <!--FEATURE LIST START--> */}
              <div className="tarifas_feature_list">
                <ul>
                <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Up to 700</span> Max jobs
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    <span>Scheduled up to 21.700</span>
                  </li>
                  <li>
                    <i className="las la-check text-secondary"></i>
                    Per month
                  </li>
                </ul>
              </div>
              {/* <!--//FEATURE LIST END--> */}

              {/* <!--BUTTON START--> */}
              <div className="tarifas_price_btn clearfix">
                <Link className="btn btn-primary" to="/signup">
                  START FREE TRIAL
                </Link>
              </div>
              {/* <!--//BUTTON END--> */}
            </div>
            {/* <!--//PRICE CONTENT END--> */}
          </div>
        </div>
        {/* <!--//BLOCK ROW END--> */}
      </div>
    </div>
  )
}

Pricing.defaultProps = {
  noTitle: false,
}

export default Pricing
