export default {
  particles: {
    number: {
      value: 200,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: "#17a0f7",
    },
    size: {
      value: 5,
      random: true,
    },
    opacity: {
      value: 0.5,
      random: true,
    },
    line_linked: {
      enable: true,
      distance: 100,
      color: "#17a0f7",
      opacity: 0.5,
      width: 1,
    },
    move: {
      enable: true,
      speed: 5,
      out_mode: "out",
    },
  },
}
