import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Toast from "react-bootstrap/Toast"
import LoadingModal from "../loading-modal/LoadingModal"

const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [failure, setFailure] = useState(false)

  const toggleSuccess = () => setSuccess(!success)
  const toggleInvalid = () => setInvalid(!invalid)
  const toggleFailure = () => setFailure(!failure)
  
  const [fields, setFields] = useState({
    username: "",
    email: "",
    message: "",
  })

  const isEmail = email => {
    var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailReg.test(email)
  }

  const handleChange = e => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async e => {
    if (isEmail(fields.email) && fields.message) {
      console.log("submit data...")
      setLoading(true)
      try {
        const res = await axios.post(
          process.env.GATSBY_MAIL_API + "/contact",
          {
            contactData: { ...fields },
          }
        )

        console.log(res)

        setFields({
          username: "",
          email: "",
          message: "",
        })

        setLoading(false)
        navigate("/")
      } catch (err) {
        setFields({
          username: "",
          email: "",
          message: "",
        })
        setLoading(false)
        setFailure(true)
        console.log(err)
      }
    } else {
      console.log("Invalid fields..")
      setInvalid(true)
    }
  }

  return (
    <div className="container-fluid contacto">
      <div className="container">
        <div className="bg-white wow fadeInUp">
          <div className="encabezadocontacto">
            <i className="las la-envelope"></i>
          </div>
          <h1 className="text-center">Contact Us</h1>
          <form>
            <div className="row">
              {/* <div className="col-md-6 col-sm-12 form-group">
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="Your name"
                  value={fields.username}
                  onChange={handleChange}
                />
              </div> */}
              <div className="col form-group">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Your email"
                  value={fields.email}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 col-sm-12 form-group">
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Your message"
                  value={fields.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-md-12 col-sm-12 form-group text-center">
                <button type="submit" className="btn btn-primary mt-3" 
                onClick={e => {
                    e.preventDefault()
                    handleSubmit(e)
                  }}>
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <LoadingModal show={loading} />
      <Toast
        show={failure}
        onClose={toggleFailure}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          bottom: 50,
          left: "50%",
          transform: "translate(-50%, 0)",
          backgroundColor: "#dc3545",
          color: "white",
        }}
      >
        <Toast.Body>The message could not be sent.</Toast.Body>
      </Toast>
      <Toast
        show={success}
        onClose={toggleSuccess}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          bottom: 50,
          left: "50%",
          transform: "translate(-50%, 0)",
          backgroundColor: "#28a745",
          color: "#343a40",
        }}
      >
        <Toast.Body>¡Message sent!</Toast.Body>
      </Toast>
    </div>
  )
}

export default ContactForm
