import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import barcaImg from "../../images/barca.svg"

const Productivity = () => {
  return (
    <Container fluid className="competencia bg-light">
      <Container>
        <Row>
          <Col sm={12} md={6} data-sal="slide-up">
            <h1 className="text-secondary">More complexity!</h1>
            <p className="text-primary mt-3 mb-5 font-weight-normal">As our projects grow, the complexity tends to increase and our productivity begins to decline.
Our platform is designed so that the scaling of complexity is transparent and we obtain higher ROI
            </p>

            <h1 className="text-secondary pt-0 pt-md-3">Why Us??</h1>
            <p className="text-primary mt-3 mb-5 mb-md-0 font-weight-normal">
            Because we are a complete solution in data processing, we simplify complex and repetitive processes, we facilitate teamwork and we streamline maintenance processes to be more productive.
            </p>
            <p className="text-primary mt-3 mb-5 mb-md-0 font-weight-normal">
              Our databases do not have to be exposed on the Internet with the security risk that this entails and we can carry out data movements between different networks transparently and without extra configurations.
            </p>
          </Col>
          <Col sm={12} md={6} data-sal="slide-up" data-sal-delay="200">
            <img src={barcaImg} width="100%" />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Productivity
