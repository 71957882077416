import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Particles from "react-particles-js"
import particlesConfig from "./particlesConfig"
import { Link } from "gatsby"
import Infograph from "../Infograph/Infograph"
import LoveSql from "../LoveSql/LoveSql"
import Productivity from "../Productivity/Productivity"
import Features from "../Features/Features"
import Pricing from "../Pricing/Pricing"
import ContactForm from "../ContactForm/ContactForm"
import homeImg from "../../images/home.svg"

const Home = () => {
  return (
    <Container fluid className="banner">
      <Particles
        params={particlesConfig}
        id="particles-js"
        style={{ maxHeight: "600px", maxWidth: "650px" }}
      />
      <Container style={{ position: "relative" }}>
        <Row>
          <Col sm={12} md={6} className="bg-white">
            <h2 data-sal="slide-up">A Modern Data-driven Platform</h2>
            <div
              className="text-primary"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-duration="500"
            >
              <div>
                <b>Open Datastage</b> is a data-driven platform that abstracts us from complexity, transparently orchestrates and automated processes and offers higher-level services to simplify development, regardless of whether the infrastructure is on-premise/on-cloud.
                <br /><br />
                Accessible by all team members, without barriers, without the need to learn new languages or use complex components.
                <br /><br />
                Some of the features you will have access to:
                <br />
                <br />
                <ul>
                  <li>Copy of data between a source and a destination, without mappings and regardless of whether they are on the same network or server.</li>
                  <li>Automatic looping in sql.</li>
                  <li>Use of variables in processes</li>
                  <li>Definition of controls to validate the data.</li>
                  <li>Sheduling, logs, security rights, etc.</li>
                </ul>
              </div>
            </div>
            <Link
              to="/signup"
              className="btn btn-primary mt-4"
              data-sal="flip-left"
              data-sal-delay="500"
            >
              START FREE TRIAL
            </Link>
          </Col>
          <Col sm={12} md={6} className="text-center">
            <img
              src={homeImg}
              className="mt-5 mt-lg-0"
              data-sal="fade"
              data-sal-duration="500"
            />
            <div style={{position: "relative", paddingBottom: "56.25%", height: 0, marginTop: "25px"}}>
              <iframe 
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}
                src="https://www.youtube-nocookie.com/embed/YAJdvErWLyw?rel=0&modestbranding=1" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen />
            </div>
          </Col>
        </Row>
      </Container>
      <Infograph />
      <LoveSql />
      <Productivity />
      <Features />
      <Pricing />
      <ContactForm />
    </Container>
  )
}

export default Home
